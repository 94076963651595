const LOCAL_STORAGE_KEY = 'policyAgree';

export const policyAgree = () => {
    const value: string = localStorage.getItem(LOCAL_STORAGE_KEY) ?? '';
    const policyAgreeWindow = document.querySelector<HTMLElement>('.policyAgree');
    const policyAgreeButton = document. querySelector<HTMLElement> ('.policyAgree__button');

    if (value !== 'accepted') {
        policyAgreeWindow?.setAttribute('style', 'display: flex');
    }

    policyAgreeButton?.addEventListener('click', () => {
        policyAgreeWindow?. setAttribute('style', 'display: none');
        localStorage.setItem(LOCAL_STORAGE_KEY, 'accepted');
    });
};

export default policyAgree;
